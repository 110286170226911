@import '../../scss/variables';

.favoriteButton {
    background-color: transparent;
    border: .5px solid $red;
    border-radius: 15px; 
    font-family: $sans-serif-normal;    
    font-weight: $sans-normal;
    letter-spacing: -.2px;
    padding: 8px 16px;
    text-transform: none;

    &,
    &::before {
        color: $red;
    }

    &::before {
        content: '\f006';
        font-family: $fontAwesome;
        margin-right: 8px;
        transition: 0.5s ease-in-out;
    }

    &[data-favorited='true'] {
        &::before {
            content: '\f005';
        }
    }

    &:hover {
        background-color: $red;

        &,
        &::before {
            color: $white;
        }
    }
}