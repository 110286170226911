@import '../../scss/variables';

.container {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -16px;
    padding-bottom: 16px;

    &.noCta {
        background-image: url('../../imgs/phoenix.svg');
        background-position: calc(100% - 16px) top;
        background-repeat: no-repeat;
        border-bottom: 1px solid $lightgrey;
        margin-bottom: 48px;
    }

    &.hasCta {
        margin-bottom: 16px;
    }

    &:first-child {
        background-position: calc(100% - 16px) 64px;
        padding-top: 64px;
        
    }
    
    &.isEditing {
        margin-top: 0px;
    }
}

.container,
.titleContainer {
    align-items: center;
    display: flex;
}

.titleContainer {
    &:not(:only-child) {
        margin-right: 48px;
    }
}

.titleContainer,
.ctaContainer {
    margin-top: 16px;
}

.favoriteButton {
    margin-left: 16px;
}

.ctaContainer {
    margin-right: 5px;
}

.cta {
    font-family: $sans-serif-medium;
    font-weight: $sans-medium;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

@media (max-width: $mobile) {
    .container {
        background-position: right top;
        margin-top: 0;
        
        &.noCta {
            background-image: none;
        }

        &:first-child {
            background-position: calc(100% - 64px) 48px;
            padding-top: 48px;
        }
    }

    .container,
    .titleContainer {
        display: block;
    }

    .titleContainer {
        &:not(:only-child) {
            margin-right: 0;
        }
    }

    .favoriteButton {
        margin-top: 16px;
        margin-left: 0;
    }
}